import type { Actions } from '@web3-react/types';
import { MetaMask } from '@web3-react/metamask';
import getConfig from 'config';

export const connect = async (actions: Actions) => {
  const {
    NEXT_PUBLIC_NETWORK_CHAIN_ID, NEXT_PUBLIC_NETWORK_NAME, NEXT_PUBLIC_BLOCKCHAIN_URL, NEXT_PUBLIC_NETWORK_SCAN,
  } = getConfig();
  if (!NEXT_PUBLIC_NETWORK_CHAIN_ID) throw new Error('Chain required');
  if (!NEXT_PUBLIC_NETWORK_NAME) throw new Error('Chain name required');
  if (!NEXT_PUBLIC_BLOCKCHAIN_URL) throw new Error('Rpc url required');
  if (!NEXT_PUBLIC_NETWORK_SCAN) throw new Error('Block explorer url required');
  const metamask = new MetaMask({ actions });
  await metamask.activate({
    chainId: NEXT_PUBLIC_NETWORK_CHAIN_ID,
    chainName: NEXT_PUBLIC_NETWORK_NAME,
    rpcUrls: [NEXT_PUBLIC_BLOCKCHAIN_URL],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: [NEXT_PUBLIC_NETWORK_SCAN],
  });
};