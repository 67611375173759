import React, {
  memo,
  FC,
  useCallback,
  useState,
  useMemo,
} from 'react';
import dynamic from 'next/dynamic';
import { Formik, FormikHelpers } from 'formik';
import { Box } from 'uikit/Box';
import { Button } from 'uikit/Buttons';
import { Spinner } from 'uikit/Spinner';
import { InputFormik } from 'uikit/Input';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { FormValues, Fields, LoginProps } from './types';
import { getValidationSchema } from './helpers';
import classes from './Login.module.scss';

const Modal = dynamic(() => import('../../uikit/Modal/Modal'), { ssr: false });

export const Login: FC<LoginProps> = memo(({
  show, onClose: onCloseProp, login,
}) => {
  const theme = useAppSelector(themeSelector);
  const [isValidating, setIsValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const [initialValues] = useState<FormValues>({ token: '' });
  const onClose = useCallback(() => onCloseProp(), [onCloseProp]);
  const onSubmit = useCallback((submitForm) => async () => {
    setIsValidating(true);
    submitForm();
  }, []);

  const onSubmitForm = useCallback(async (
    formValues: FormValues,
    { setFieldError }: FormikHelpers<FormValues>,
  ) => {
    setLoading(true);
    setIsValidating(true);
    const { token } = formValues || {};
    try {
      await login(token?.trim());
    } catch (e) {
      setFieldError(Fields.token, (e as Error).message);
    }
    setLoading(false);
  }, [login]);
  return (
    <Modal
      show={show}
      theme={theme}
      onClose={onClose}
      dialogClassName={classes.dialog}
      bodyClassName={classes.body}
    >
      <Formik<FormValues>
        validateOnChange={isValidating}
        validateOnBlur={false}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmitForm}
      >
        {({ submitForm }) => {
          return (
            <form
              onSubmit={(e) => e.preventDefault()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSubmit(submitForm)();
                }
              }}
            >
              <Box direction="column" className={classes[theme]}>
                {loading && (
                  <Spinner fullscreen />
                )}
                <Box direction="column" alignItems="center">
                  <Box className={classes.header}>Get Tokens</Box>
                  <Box className={classes.subTitle}>
                    As an anti-fraud measure, please verify your Access Token to receive test TEE and MATICs.
                  </Box>
                  <InputFormik
                    autosize
                    as="textarea"
                    minRows={2}
                    placeholder="Enter the access token..."
                    name={Fields.token}
                    classNameWrap={classes.inputWrap}
                    theme={theme}
                  />
                  <Box justifyContent="center">
                    <Button
                      className={classes.btn}
                      theme={theme}
                      variant="base"
                      onClick={onSubmit(submitForm)}
                    >
                      Get Tokens
                    </Button>
                  </Box>
                </Box>
              </Box>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
});
